import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'

import ConnectedRouter from './components/ConnectedRouter'
import App from './components/App'

import store from './store'
import routes from './features/routes'

import './index.css'

import UserManager from './services/UserManager'
import history from './history'
import { unregister } from './registerServiceWorker'

ReactDOM.render(
    (
        <Provider store={ store }>     
            <OidcProvider userManager={ UserManager } store={ store }>
                <ConnectedRouter history={ history }>
                    <App routes={ routes } />
                </ConnectedRouter>
            </OidcProvider>
        </Provider>
    ), document.getElementById('root')
)

unregister()
