import { dispatch } from '@rematch/core'
import produce from 'immer'
import { findIndex } from 'lodash'

import api from './api'
import NotificationService from 'src/services/NotificationService'

export default { 
    state: {
        list: {},
        selected: {},
        total: 0
    },

    reducers: {
        set: produce((draft, body = {}) => {
            draft.list = {}
            body.tenants.forEach(tenant => {
                draft.list[tenant.id] = tenant
            })

            draft.total = body.total
        }),

        select: produce((draft, selected) => {
            draft.selected = selected
        }),

        removeProvider: produce((draft, providerId) => {
            let index = findIndex(draft.selected.providers, x => x.id === providerId)
            if (index > -1) {
                draft.selected.providers.splice(index, 1)
            }
        })
    },

    selectors: {
        list(state) {
            return Object.values(state.tenants.list)
        },

        selected(state) {
            return state.tenants.selected
        },

        total(state) {
            return state.tenants.total
        }
    },

    effects: {
        async search(filter = {}) {
            try { 
                const response = await api.list(filter)
                dispatch.tenants.set(response.body)
            } catch (error) { 
                dispatch(NotificationService.notifyError(error, 'An error occurred while searching tenants'))
            }
        },

        async get(id = '') {
            try {
                const response = await api.get(id)
                dispatch.tenants.select(response.body)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while getting tenant ${id}`))
            }
        },

        async update(tenant = {}) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${tenant.id}"...` }))
                const response = await api.put(tenant.id, tenant)

                dispatch.tenants.select(response.body)

                dispatch(NotificationService.notifySaved(message, `Saved "${tenant.id}"`))
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while saving tenant ${tenant.id}`))
            }   
        },

        async add(tenant = {}) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Adding "${tenant.id}"...` }))
                const response = await api.post(tenant)

                dispatch(NotificationService.notifySaved(message, `Added "${tenant.id}"`))
                dispatch.router.push(`/tenants/${response.body.id}`)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while adding tenant ${tenant.id}`))
            }
        },
        
        async deleteProvider({ tenantId = '', provider = {} }) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Deleting link to provider with id "${provider.name}"...` }))
                const response = await api.providers.delete(tenantId, provider.id)

                if (response.body) {
                    dispatch.tenants.removeProvider(provider.id)
                    dispatch(NotificationService.notifySaved(message, `Deleted link to provider with id "${provider.name}"`))
                } else {
                    dispatch(NotificationService.notifyWarning(message, `Something is not letting us delete the link to provider "${provider.name}"`))
                }
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while deleting link to provider ${provider.name}`))
            }
        }
    }
}
