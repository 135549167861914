import deepExtend from 'deep-extend'
import defaults from './modes/defaults'
import development from './modes/development'
import * as helpers from './helpers'

const config = {
    defaults: {
        ...helpers.createConfigSection('env', {
            public_path: process.env.PUBLIC_PATH,
            mode: process.env.NODE_ENV || 'development',
            isBrowser: typeof window !== 'undefined',
            isDev: process.env.NODE_ENV !== 'production'
        }),
        
        ...defaults
    },
    development
}

const externalConfig = window.IDPUI_CONFIG || {}

const completeConfig = deepExtend({}, config.defaults, config[config.defaults.env_mode], externalConfig)

export function getConfigSection(section) {
    return helpers.getConfigSection(completeConfig, section)
}

export function getConfig(key) {
    return helpers.getConfig(completeConfig, key)
}
