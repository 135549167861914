import { notify, POSITIONS } from 'reapop'

const NotificationTypes = {
    saving: 'SAVING',
    saved: 'SAVED',
    error: 'ERROR',
    warning: 'WARNING'
}

const defaultMessage = {
    message: '',
    position: POSITIONS.topCenter,
    dismissible: true,
    dismissAfter: 2000,
    closeButton: true
}

function createNotification(type, message) {
    let mess = { ...defaultMessage, ...message }
    if (type) {
        switch (type) {
            case NotificationTypes.saving:
                mess.status = 'info'
                break
            case NotificationTypes.saved:
                mess.status = 'success' 
                break
            case NotificationTypes.error:
                mess.status = 'error'
                mess.dismissAfter = 10000
                mess.onAdd = () => {
                    console.log(mess.message)
                }
                break
            case NotificationTypes.warning:
                mess.status = 'warning'
                break
            default: 
                break
        }
    }

    return notify(mess)
}

export default {
    notifySaving: message => {
        return createNotification(NotificationTypes.saving, message)
    },
    notifySaved: (message, text) => {
        return createNotification(NotificationTypes.saved, { ...message, message: text })
    },
    notifyWarning: (message, text) => {
        return createNotification(NotificationTypes.warning, { ...message, message: text })
    },
    notifyError: (error, message) => {
        return createNotification(NotificationTypes.error, { message: `${message}: ${error}` })
    }
}