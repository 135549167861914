import api, { getAccessToken } from 'src/services/api'

export default {
    list(filter = {}) { 
        return api
            .get('/api/clients')
            .authBearer(getAccessToken())
            .query(filter)
            .type('application/json')
    },

    get(clientId = '') {
        return api
            .get(`/api/clients/${clientId}`)
            .authBearer(getAccessToken())
            .type('application/json')
    },

    put(clientId = '', client = {}) {
        return api
            .put(`/api/clients/${clientId}`)
            .authBearer(getAccessToken())
            .send(client)
            .type('application/json')
    }
}