import api, { getAccessToken } from 'src/services/api'

export default {
    post(clientId = '', secret = {}) {
        return api
            .post(`/api/clients/${clientId}/secrets`)
            .authBearer(getAccessToken())
            .send(secret)
            .type('application/json')
    },

    delete(clientId = '', secretId = 0) {
        return api
            .del(`/api/clients/${clientId}/secrets/${secretId}`)
            .authBearer(getAccessToken())
            .type('application/json')
    }
}