import * as React from 'react'
import PropTypes from 'prop-types'
import {
    TransitionGroup,
    CSSTransition
} from 'react-transition-group'

import {
    Switch,
    Route
} from 'react-router-dom'

import Notification from '../Notification'
import Navigation from '../Navigation'
import Breadcrumb from '../Breadcrumb'
import LoginInfo from '../LoginInfo'
import './App.css'

class App extends React.Component {

    static propTypes = {
        routes: PropTypes.array
    }

    static defaultProps = {
        routes: []
    }

    getLocationKey = pathname => {
        return pathname.split('/').slice(0, 2).join('/')
    }

    render() {
        const { routes } = this.props
        // bypass react router if idp logout is requested
        if (!window.location.pathname.includes('/account/logout')) {
            return (
                <div className="ao-app">
                    <Notification />

                    <div className="ao-app__navigation-container">
                        <Navigation routes={ routes } vertical />
                    </div>

                    <div className="ao-app__content">
                        <header className="ao-app__header-container">
                            <div className="ao-app__brand">
                                <span className="ao-app__brandname">
                                    GIR Identiteit
                                </span>
                            </div>
                            <Breadcrumb />
                            <div className="ml-auto">
                                <LoginInfo />
                            </div>
                        </header>

                        <div className="ao-app__route-container">
                            <Route render={ ({ location }) => (
                                <TransitionGroup>
                                    <CSSTransition key={ this.getLocationKey(location.pathname) } classNames="ao-route" timeout={ 300 }>
                                        <Switch location={ location }>
                                            {routes.map((route, i) =>
                                                <Route key={ i } { ...route } />
                                            )}
                                        </Switch>
                                    </CSSTransition>
                                </TransitionGroup>
                            ) } />
                        </div>
                    </div>
                </div>

            )
        }
    }
}

export default App