import React from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router'
import { connect } from 'react-redux'

class ConnectedRouter extends React.Component {
    static propTypes = {
        store: PropTypes.object,
        history: PropTypes.object.isRequired,
        children: PropTypes.node,
        isSSR: PropTypes.bool,
        locationChanged: PropTypes.func
    }

    static contextTypes = {
        store: PropTypes.object
    }

    handleLocationChange = (location, action) => {
        this.props.locationChanged({
            location,
            action
        })
    }

    componentWillMount() {
        const { store: propsStore, history, isSSR } = this.props
        this.store = propsStore || this.context.store

        if (!isSSR) { 
            this.unsubscribeFromHistory = history.listen(this.handleLocationChange) 
        }

        this.handleLocationChange(history.location)
    }

    componentWillUnmount() {
        if (this.unsubscribeFromHistory) {
            this.unsubscribeFromHistory() 
        }
    }

    render() {
        return <Router { ...this.props } />
    }
}

function mapDispatchToProps(dispatch) {
    return {
        locationChanged: dispatch.router.locationChanged
    }
}

export default connect(null, mapDispatchToProps)(ConnectedRouter)