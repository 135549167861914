import { dispatch } from '@rematch/core'
import produce from 'immer'

import NotificationService from 'src/services/NotificationService'
import api from './api'

export default {
    state: {
        list: {},
        total: 0,
        selected: {},
        protocols: []
    },

    reducers: {
        set: produce((draft, body = {}) => {
            draft.list = {}
            body.providers.forEach(provider => {
                draft.list[provider.id] = provider
            })

            draft.total = body.total
        }),
        selected: produce((draft, selected) => {
            draft.selected = selected
        }),

        updatedProtocol: produce((draft, protocol) => {
            draft.selected.protocol = protocol
        }),

        protocols: produce((draft, protocols = []) => {
            draft.protocols = protocols
        }),

        remove: produce((draft, providerId) => {
            delete draft.list[providerId]
        })
    },

    selectors: {
        list(state) {
            return Object.values(state.providers.list)
        },

        total(state) {
            return state.providers.total
        },

        tenants(state) {
            return Object.values(state.tenants.list)
        },

        selected(state) {
            return state.providers.selected
        },

        supportedProtocols(state) {
            return state.providers.protocols
        }
    },
    
    effects: {
        async search(filter = {}) {
            try {
                const response = await api.list(filter)
                dispatch.providers.set(response.body)
            } catch (error) { 
                dispatch(NotificationService.notifyError(error, 'An error occurred while searching providers'))
            }
        },

        async get(id = '') { 
            try {
                const response = await api.get(id)
                dispatch.providers.selected(response.body)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while getting provider ${id}`))
            }
        },

        async getSupportedProtocols() {
            try {
                const response = await api.protocol.supported()
                dispatch.providers.protocols(response.body)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, 'An error occurred while getting authentication schemes'))
            }
        },

        async update(provider = {}) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${provider.name}"...` }))
                const response = await api.put(provider.id, provider)

                dispatch.providers.selected(response.body)
                
                dispatch(NotificationService.notifySaved(message, `Saved "${provider.name}"`))
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while saving provider ${provider.name}`))
            }
        },

        async updateProtocol(protocol = {}) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${protocol.description}"...` }))
                const response = await api.protocol.put(protocol.id, protocol)

                dispatch.providers.updatedProtocol(response.body)

                dispatch(NotificationService.notifySaved(message, `Saved "${protocol.description}"`))
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while saving protocol ${protocol.description}`))
            }
        },

        async add(provider = {}) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Adding "${provider.name}"...` }))
                const response = await api.post(provider)
                
                dispatch(NotificationService.notifySaved(message, `Saved "${provider.name}"`))
                dispatch.router.push(`/providers/${response.body.id}/protocol`)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while adding provider ${provider.name}`))
            }
        },
        
        async delete(provider = {}) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Deleting "${provider.name}"...` }))
                const response = await api.delete(provider.id)

                if (response.body) {
                    dispatch.providers.remove(provider.id)
                    dispatch(NotificationService.notifySaved(message, `Deleted provider "${provider.name}"`))
                } else {
                    dispatch(NotificationService.notifyWarning(message, `Something is not letting us delete provider "${provider.name}"`))
                }
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while deleting provider ${provider.name}`))
            }
        }
    }
}