import api, { getAccessToken } from 'src/services/api'

export default {
    list(filter = {}) {
        return api
            .get('/api/providers')
            .authBearer(getAccessToken())
            .query(filter)
            .type('application/json')
    },
    
    get(id) {
        return api
            .get(`/api/providers/${id}`)
            .authBearer(getAccessToken())
            .type('application/json')
    },
    
    put(id, provider) {
        return api
            .put(`/api/providers/${id}`)
            .authBearer(getAccessToken())
            .send(provider)
            .type('application/json')
    },
    
    post(provider = {}) {
        return api
            .post('/api/providers')
            .authBearer(getAccessToken())
            .send(provider)
            .type('application/json')
    },
    
    delete(providerId) { 
        return api
            .del(`/api/providers/${providerId}`)
            .authBearer(getAccessToken())
            .type('application/json')
    },
    
    protocol: {
        put(id, protocol) {
            return api
                .put(`/api/protocols/${id}`)
                .authBearer(getAccessToken())
                .send(protocol)
                .type('application/json')
        },
        
        supported() {
            return api
                .get('/api/protocols')
                .authBearer(getAccessToken())
                .type('application/json')
        }
    }
}