import { dispatch } from '@rematch/core'

import NotificationService from 'src/services/NotificationService'
import api from '../api'

export default {
    async addPostLogoutUri({ clientId = '', uri = '' }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Adding "${uri}"...` }))
            const response = await api.postLogoutUris.post(clientId, { uri })

            dispatch.clients.add({ key: 'postLogoutUris', item: response.body.value })

            dispatch(NotificationService.notifySaved(message, `Added "${uri}"`))
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while adding uri ${uri} for client ${clientId}`))
        }
    },
    async editPostLogoutUri({ clientId = '', uri = {} }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${uri.uri}"...` }))
            const response = await api.postLogoutUris.put(clientId, uri)

            dispatch.clients.edit({ key: 'postLogoutUris', item: response.body })

            dispatch(NotificationService.notifySaved(message, `Saved "${uri.uri}"`))
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while saving uri ${uri.uri} for client ${clientId}`))
        }
    },
    async deletePostLogoutUri({ clientId = '', id = 0 }) {
        try {
            const message = await dispatch(NotificationService.notifySaving({ message: `Deleting uri with id "${id}"...` }))
            const response = await api.postLogoutUris.delete(clientId, id)

            if (response.body) {
                dispatch.clients.delete({ key: 'postLogoutUris', id })
                dispatch(NotificationService.notifySaved(message, `Deleted uri with id "${id}"`))
            } else {
                dispatch(NotificationService.notifyWarning(message, `Something is not letting us delete uri with "${id}"`))
            }
        } catch (error) {
            dispatch(NotificationService.notifyError(error, `An error occurred while deleting uri with id ${id} for client ${clientId}`))
        }
    }
}