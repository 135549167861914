import { createConfigSection } from '../helpers'

export default {

    ...createConfigSection('api', {
        //base: 'https://localhost:44343',
        base: 'http://localhost:59764',
        requestTimeout: 2000
    }),

    ...createConfigSection('oidc', {
        authority: 'https://localhost:44343',
        client_id: 'localhost.client'
    })
}
