import api, { getAccessToken } from 'src/services/api'

const userApi = {
    list(filter = {}) {
        return api
            .get('/api/users')
            .authBearer(getAccessToken())
            .query(filter)
            .type('application/json')
    },
    
    get(id) {
        return api
            .get(`/api/users/${id}`)
            .authBearer(getAccessToken())
            .type('application/json')
    },

    put(user) {
        return api
            .put(`/api/users/${user.id}`)
            .authBearer(getAccessToken())
            .send(user)
            .type('application/json')
    },

    impersonate({ clientId, userId }) {
        return api
            .post(`/api/users/${userId}/impersonate/${clientId}`)
            .authBearer(getAccessToken())
            .send({})
            .type('application/json')
    },

    changePassword({ userId, password }) {
        return api
            .put(`/api/users/${userId}/password`)
            .authBearer(getAccessToken())
            .send({ password })
            .type('application/json')
    }
}

export default userApi
