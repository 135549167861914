import React from 'react'

function ViewContent({ children }) {
    return (
        <div className="ao-view__content">
            { children }
        </div>
    )
}

export default ViewContent