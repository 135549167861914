import tenants from './tenants/model'
import users from './users/model'
import clients from './clients/model'
import providers from './providers/model'

export default {
    tenants,
    users,
    providers,
    clients
}