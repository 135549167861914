import { createUserManager } from 'redux-oidc'
import { getConfigSection } from '../config'

const oidc = getConfigSection('oidc')
const userManagerConfig = {
    ...oidc,
    'redirect_uri': `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}`: ''}/oidc-callback`,
    'silent_redirect_uri': `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
    'automaticSilentRenew': true,
    'filterProtocolClaims': true
}

const userManager = createUserManager(userManagerConfig)
export default userManager
