import { dispatch } from '@rematch/core'
import { select } from '@rematch/select'
import produce from 'immer'

import NotificationService from 'src/services/NotificationService'
import api from './api'

export default {
    state: {
        list: {},
        selected: {}
    },

    reducers: {
        set: produce((draft, users = []) => {
            draft.list = {}
            users.forEach(user => {
                draft.list[user.id] = user
            })
        }),
        
        select: produce((draft, user = {}) => {
            draft.selected = user
            return draft
        })
    },

    effects: {
        async search(filter = {}) {
            try {
                const response = await api.list(filter)
                return dispatch.users.set(response.body)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, 'An error occurred while searching users'))
            }
        },

        async get(name) {
            try {
                const response = await api.get(name)
                return dispatch.users.select(response.body)
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while getting user ${name}`))
            }
        },

        async update(user) {
            try {
                const message = await dispatch(NotificationService.notifySaving({ message: `Saving "${user.name}"...` }))
                await api.put(user)
                dispatch(NotificationService.notifySaved(message, `Saved "${user.name}"`))
            
            } catch (error) {
                if (error.status >= 400 && error.status < 500) {
                    const { errors } = JSON.parse(error.response.text)
                    const errorMessages = errors.map(e => e.description)

                    dispatch(NotificationService.notifyError(errorMessages, 'Password change failed.'))
                    return
                }

                dispatch(NotificationService.notifyError(error, `An error occurred while saving user ${user.name}`))
            }
        },

        async impersonate({ clientId, userId }) {
            // in a new window
            try {
                const result = await api.impersonate({ clientId, userId })
                const { isActive, redirectUrl } = result.body

                // redirect to uri if active
                if (isActive) {
                    window.open(redirectUrl, '_blank')
                } else {
                    dispatch(NotificationService.notifyError('', 'The server did not return an active impersonation session.'))
                }
            } catch (error) {
                dispatch(NotificationService.notifyError(error, `An error occurred while starting impersonation session for client ${clientId}.`))
            }
        },

        async changePassword({ userId, password }) {
            try {
                await api.changePassword({ userId, password })
                dispatch(NotificationService.notifySaved('', 'Password of the user has been changed.'))
            } catch (error) {
                if (error.status >= 400 && error.status < 500) {
                    const { errors } = JSON.parse(error.response.text)
                    const errorMessages = errors.map(e => e.description)

                    dispatch(NotificationService.notifyError(errorMessages, 'Password change failed.'))
                    return
                }

                dispatch(NotificationService.notifyError(error, 'An error occurred while changing the users\'s password.'))
            }
        }
    },

    selectors: {
        list(state) {
            return Object.values(state.users.list)
        },

        selected(state) {
            return state.users.selected
        },

        tenantsFilter(state) {
            return select.tenants.list(state).sort(t => t.description)
        }
    }
}
