import createRouterModel from './model'

function createRouterPlugin(history) {
    return {
        config: {
            models: { router: createRouterModel(history) }
        }
    }
}

export default createRouterPlugin