import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import ViewHeader from './ViewHeader'
import ViewContent from './ViewContent'

import './View.css'

function View({ children, className }) {
    const classes = cx('ao-view', className)
    return (
        <div className={ classes }>
            { children }
        </div>
    )
}

View.Content = ViewContent
View.Header = ViewHeader

View.propTypes = {
    header: PropTypes.oneOfType([ PropTypes.func, PropTypes.object ]),
    className: PropTypes.string
}

export default View