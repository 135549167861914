import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { select } from '@rematch/select'

function UserDetailBreadcrumb({ name }) {
    return (
        <span>{ name }</span>
    )
}

UserDetailBreadcrumb.propTypes = {
    name: PropTypes.string
}

UserDetailBreadcrumb.defaultProps = {
    name: ''
}

function mapStateToProps(state) {
    return { name: select.users.selected(state).userName }
}

export default connect(mapStateToProps)(UserDetailBreadcrumb)