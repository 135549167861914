import fontawesome from '@fortawesome/fontawesome'
import faCheckSquare from '@fortawesome/fontawesome-free-solid/faCheckSquare'
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus'
import faExclamationTriangle from '@fortawesome/fontawesome-free-solid/faExclamationTriangle'
import faUserFriends from '@fortawesome/fontawesome-free-solid/faUserFriends'
import faHome from '@fortawesome/fontawesome-free-solid/faHome'
import faPlug from '@fortawesome/fontawesome-free-solid/faPlug'
import faCog from '@fortawesome/fontawesome-free-solid/faCog'
import faUsers from '@fortawesome/fontawesome-free-solid/faUsers'
import faUser from '@fortawesome/fontawesome-free-solid/faUser'
import faDatabase from '@fortawesome/fontawesome-free-solid/faDatabase'
import faDesktop from '@fortawesome/fontawesome-free-solid/faDesktop'
import faIdCard from '@fortawesome/fontawesome-free-solid/faIdCard'
import faSave from '@fortawesome/fontawesome-free-solid/faSave'
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck'
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes'
import faEllipsisH from '@fortawesome/fontawesome-free-solid/faEllipsisH'
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV'
import faTrash from '@fortawesome/fontawesome-free-solid/faTrash'
import faEdit from '@fortawesome/fontawesome-free-solid/faEdit'
import faExchangeAlt from '@fortawesome/fontawesome-free-solid/faExchangeAlt'
import faBars from '@fortawesome/fontawesome-free-solid/faBars'

import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft'
import faAngleDoubleLeft from '@fortawesome/fontawesome-free-solid/faAngleDoubleLeft'
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight'
import faAngleDoubleRight from '@fortawesome/fontawesome-free-solid/faAngleDoubleRight'
import faArrowLeft from '@fortawesome/fontawesome-free-solid/faArrowLeft'
import faUserSecret from '@fortawesome/fontawesome-free-solid/faUserSecret'

const navigation = [ faHome, faPlug, faCog, faDatabase, faDesktop, faUsers, faUser, faIdCard, faBars ]
const actions = [ faSave, faPlus, faEllipsisH, faEllipsisV, faTrash, faEdit, faCheck, faExchangeAlt, faTimes ]
const directions = [ faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight, faArrowLeft ]

fontawesome.library.add([
    faCheckSquare, 
    faUserFriends,
    faExclamationTriangle,
    ...navigation,
    ...actions,
    ...directions,
    faUserSecret
])

export { default } from '@fortawesome/react-fontawesome'